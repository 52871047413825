import style from './Explorer.module.css'
import useTranslation from 'next-translate/useTranslation'
import classNames from 'classnames/bind'
import { Swipeable } from '../helpers/swipeable'
import { useState, useEffect, useRef, useContext } from 'react'
import { LayoutContext } from './sg/Layout'
import { useRouter } from 'next/router'
import { usePremiums } from '../hooks/usePrices'
import Link from 'next/link'
import useSWR from 'swr'
import resolveProductUnit from '../helpers/resolveProductUnit'

export default function Explorer () {
  const c = classNames.bind(style)
  const { t } = useTranslation('main')
  const backingTab = useRef()
  const ownersTab = useRef()
  const router = useRouter()
  const { locale } = router
  const lang = locale.split('-')[0]
  const [loading, setLoading] = useState(true)
  const [explorerVisible, setExplorerVisible] = useState()
  const [tab, setTab] = useState('backing')
  const { data: owners, error } = useSWR(['/api/gb-savings/holdings/list', false])
  const ownersLoading = !owners && !error
  const [layoutContext] = useContext(LayoutContext)
  const { currency, productUnit } = layoutContext
  const { productUnitShortLabel, productPriceResolution, productUnitFractionDigits } = resolveProductUnit(productUnit, 1)
  const [itemClicked, setItemClicked] = useState()
  const [allocationLoading, setAllocationLoading] = useState()
  const [allocationDetails, setAllocationDetails] = useState()

  const premiums = usePremiums(currency, locale)
  const lowestPremium = Object.entries(premiums).reduce((acc, [type, { buy }]) => {
    // Convert the percentage string to a number
    const buyPremiumValue = parseFloat(buy.replace('%', '')) / 100

    return buyPremiumValue < acc.value ? { type, value: buyPremiumValue } : acc
  }, { type: null, value: Number.POSITIVE_INFINITY })

  useEffect(() => {
    if (backingTab?.current) {
      const gramchainSupportedLangs = ['en', 'zh', 'de']
      const gramchainSupportedUnits = { gram: 'g', ounce: 'oz' }
      const gramchainSupportedCurrencies = ['SGD', 'USD']
      backingTab.current.src = `https://explorer.goldsavingsaccount.sg/?theme=2&brand=gsa&unit=${gramchainSupportedUnits[productUnit] || 'g'}&currency=${gramchainSupportedCurrencies.includes(currency) ? currency : 'USD'}&lang=${gramchainSupportedLangs.includes(lang) ? lang : 'en'}`
    }
  }, [])

  useEffect(() => {
    document.body.style.overflow = explorerVisible ? 'hidden' : 'unset'
  }, [explorerVisible])

  const handleOpen = e => {
    e.target.closest('.' + style.bullion).classList.toggle(style.open)
    setItemClicked(null)
    setAllocationDetails(null)
  }

  const showAllocation = async (ownerID, metalCode) => {
    if (itemClicked && itemClicked.ownerID === ownerID && itemClicked.metalCode === metalCode) {
      setItemClicked(null)
      return
    }
    setItemClicked({ ownerID, metalCode })
    setAllocationLoading(true)
    const response = await window.fetch('/api/allocation', {
      method: 'POST',
      mode: 'same-origin',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'error',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        ownerID,
        metalCode
      })
    })
    if (response.ok) {
      const json = await response.json()
      setAllocationDetails(json)
    }
    setAllocationLoading(false)
  }

  const maintenanceMode = false
  let i = 0
  const numberFormatter = Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 6 })

  return (
    <div className={c('explore')}>
      <h3>{t('explorer-header')}</h3>
      <div className={c('split', { visible: explorerVisible })}>
        <div>
          <ul>
            <li>
              <div>
                <img src='/images/auditable.svg' width={48} height={48} alt={t('explorer-publiclyauditable-title')} />
                <h4>{t('explorer-publiclyauditable-title')}</h4>
              </div>
              <p>
                {t('explorer-publiclyauditable-body')}
                <button className={c('explorebutton')} onClick={() => setExplorerVisible(true)}>{t('explorer-open')}</button>
              </p>
            </li>
            <li>
              <div>
                <img src='/images/goldbacked.svg' width={48} height={48} alt={t('explorer-backedbyrealgold-title')} />
                <h4>{t('explorer-backedbyrealgold-title')}</h4>
              </div>
              <p>{t('explorer-backedbyrealgold-body')}</p>
            </li>
            <li>
              <div>
                <img src='/images/redemption.svg' width={48} height={48} alt={t('explorer-redeemable-title')} />
                <h4>{t('explorer-redeemable-title')}</h4>
              </div>
              <p>{t('explorer-redeemable-body')}</p>
            </li>
            <li>
              <div>
                <img src='/images/lowfees.svg' width={48} height={48} alt={t('explorer-lowfees-title')} />
                <h4>{t('explorer-lowfees-title')}</h4>
              </div>
              <p>{t('explorer-lowfees-body', { lowestPremium: lowestPremium.value.toLocaleString(locale, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }) })} <Link href='/sg/pricing'><a>{t('explorer-lowfees-link')}</a></Link></p>
            </li>
          </ul>
        </div>
        <div className={c('exploreembed', { loading })}>
          <Swipeable onSwipedDown={() => setExplorerVisible(false)} className={c('header')} onClick={() => setExplorerVisible(false)} />
          <div className={c('wrapper')}>
            {maintenanceMode
              ? (
                <div className={c('maintenance')}>
                  <img src='/images/maintenance.svg' width={48} height={48} alt='Maintenance' style={{ transform: 'rotate(180deg)' }} />
                  <br />
                  <h2 style={{ whiteSpace: 'normal' }}>{t('explorer-maintenance-title')}</h2>
                  <p>{t('explorer-maintenance-body')}</p>
                </div>
              )
              : (
                <div className={c('container')}>
                  <div className={c('options')}>
                    <button className={tab === 'backing' ? style.active : ''} onClick={() => setTab('backing')}>{t('explorer-tab-backing')}</button>
                    <button className={tab === 'owners' ? style.active : ''} onClick={() => setTab('owners')}>{t('explorer-tab-owners')}</button>
                  </div>
                  <div className={c('tabs', tab)}>
                    <iframe className={c('backingTab')} ref={backingTab} loading='lazy' onLoad={() => setLoading(false)} />
                    <div className={c('ownersTab', { loading: ownersLoading })} ref={ownersTab}>
                      {!!owners && (
                        <>
                          <div className={c('bullion')} onClick={handleOpen}>
                            <img className={c('silverbar')} src='/images/silver.png' alt={t('explorer-silverbar-alt')} />
                            <div>{t('explorer-silver-grams')}</div>
                            <div className={c('subtext')}>{(owners.reduce?.((a, v) => (v.AgGrams || 0) + a, 0) / productPriceResolution)?.toLocaleString(locale, { maximumFractionDigits: productUnitFractionDigits })}{productUnitShortLabel} - {t('explorer-accountsamount', { amount: owners.filter?.(o => o.AgGrams > 0)?.length?.toLocaleString(locale, { maximumFractionDigits: 0 }) })}</div>
                          </div>
                          <div className={c('owners')}>
                            {owners.filter?.(o => o.AgGrams > 0)?.map?.(owner => (
                              <>
                                <div key={i++} className={c('owners-item')} onClick={() => showAllocation(owner.OwnerStarId, 'Ag')}>
                                  <span className={c('starid')}>{owner.OwnerStarId?.substring(0, 6)}XXX</span>
                                  <span>{(owner.AgGrams / productPriceResolution).toLocaleString(locale, { minimumFractionDigits: productUnitFractionDigits, maximumFractionDigits: productUnitFractionDigits })}{productUnitShortLabel}</span>
                                  <img className={(itemClicked && itemClicked.ownerID === owner.OwnerStarId && itemClicked.metalCode === 'Ag') ? c('rotate') : ''} src='/images/expand_more.svg' style={{ width: 24 }} />
                                </div>
                                {itemClicked && itemClicked.ownerID === owner.OwnerStarId && itemClicked.metalCode === 'Ag' && (
                                  <div className={c('owners-item-allocation')}>
                                    {!allocationLoading && allocationDetails
                                      ? (
                                        <>
                                          <table>
                                            <tbody>
                                              <tr>
                                                <th>{t('explorer-parcel-link')}</th>
                                                <th>{t('explorer-allocation')}</th>
                                                <th>{t('explorer-grams')}</th>
                                              </tr>
                                              {allocationDetails.gramBarAllocationList.map(item =>
                                                <tr key={item.ParcelID}>
                                                  <td>
                                                    <a href={`https://silverbullion.com.sg/STAR-Grams?r=${item.ParcelID}`} target='_blank' rel='noopener noreferrer'>{item.ParcelName}</a>
                                                  </td>
                                                  <td>{numberFormatter.format(item.WeightToBeAssigned / item.Weight * 100)}%</td>
                                                  <td>{item.WeightToBeAssigned?.toFixed(6)}g</td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>
                                          <span>{t('explorer-allocation-as-of')} {allocationDetails?.updatedAt}</span>
                                        </>
                                      )
                                      : <p style={{ fontSize: 13, margin: '0 6px' }}>Loading...</p>}
                                  </div>
                                )}
                              </>
                            ))}
                          </div>
                          <div className={c('bullion')} onClick={handleOpen}>
                            <img className={c('goldbar')} src='/images/gold.png' alt={t('explorer-goldbar-alt')} />
                            <div>{t('explorer-gold-grams')}</div>
                            <div className={c('subtext')}>{(owners.reduce?.((a, v) => (v.AuGrams || 0) + a, 0) / productPriceResolution)?.toLocaleString(locale, { maximumFractionDigits: productUnitFractionDigits })}{productUnitShortLabel} - {t('explorer-accountsamount', { amount: owners.filter?.(o => o.AuGrams > 0)?.length?.toLocaleString(locale, { maximumFractionDigits: 0 }) })}</div>
                          </div>
                          <div className={c('owners')}>
                            {owners.filter?.(o => o.AuGrams > 0)?.map?.(owner => (
                              <>
                                <div key={i++} className={c('owners-item')} onClick={() => showAllocation(owner.OwnerStarId, 'Au')}>
                                  <span className={c('starid')}>{owner.OwnerStarId?.substring(0, 6)}XXX:</span>
                                  <span>{(owner.AuGrams / productPriceResolution).toLocaleString(locale, { minimumFractionDigits: productUnitFractionDigits, maximumFractionDigits: productUnitFractionDigits })}{productUnitShortLabel}</span>
                                  <img className={(itemClicked && itemClicked.ownerID === owner.OwnerStarId && itemClicked.metalCode === 'Au') ? c('rotate') : ''} src='/images/expand_more.svg' style={{ width: 24 }} />
                                </div>
                                {itemClicked && itemClicked.ownerID === owner.OwnerStarId && itemClicked.metalCode === 'Au' && (
                                  <div className={c('owners-item-allocation')}>
                                    {!allocationLoading && allocationDetails
                                      ? (
                                        <>
                                          <table>
                                            <tbody>
                                              <tr>
                                                <th>{t('explorer-parcel-link')}</th>
                                                <th>{t('explorer-allocation')}</th>
                                                <th>{t('explorer-grams')}</th>
                                              </tr>
                                              {allocationDetails.gramBarAllocationList.map(item =>
                                                <tr key={item.ParcelID}>
                                                  <td>
                                                    <a href={`https://silverbullion.com.sg/STAR-Grams?r=${item.ParcelID}`} target='_blank' rel='noopener noreferrer'>{item.ParcelName}</a>
                                                  </td>
                                                  <td>{numberFormatter.format(item.WeightToBeAssigned / item.Weight * 100)}%</td>
                                                  <td>{item.WeightToBeAssigned?.toFixed(6)}g</td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>
                                          <span>{t('explorer-allocation-as-of')} {allocationDetails?.updatedAt}</span>
                                        </>
                                      )
                                      : <p style={{ fontSize: 13, margin: '0 6px' }}>Loading...</p>}
                                  </div>
                                )}
                              </>
                            ))}
                          </div>
                          <div className={c('bullion')} onClick={handleOpen}>
                            <img className={c('goldbar')} src='/images/platinum.png' alt={t('explorer-platinumbar-alt')} />
                            <div>{t('explorer-platinum-grams')}</div>
                            <div className={c('subtext')}>{(owners.reduce?.((a, v) => (v.PtGrams || 0) + a, 0) / productPriceResolution)?.toLocaleString(locale, { maximumFractionDigits: productUnitFractionDigits })}{productUnitShortLabel} - {t('explorer-accountsamount', { amount: owners.filter?.(o => o.PtGrams > 0)?.length?.toLocaleString(locale, { maximumFractionDigits: 0 }) })}</div>
                          </div>
                          <div className={c('owners')}>
                            {owners.filter?.(o => o.PtGrams > 0)?.map?.(owner => (
                              <>
                                <div key={i++} className={c('owners-item')} onClick={() => showAllocation(owner.OwnerStarId, 'Pt')}>
                                  <span className={c('starid')}>{owner.OwnerStarId?.substring(0, 6)}XXX</span>
                                  <span>{(owner.PtGrams / productPriceResolution).toLocaleString(locale, { minimumFractionDigits: productUnitFractionDigits, maximumFractionDigits: productUnitFractionDigits })}{productUnitShortLabel}</span>
                                  <img className={(itemClicked && itemClicked.ownerID === owner.OwnerStarId && itemClicked.metalCode === 'Pt') ? c('rotate') : ''} src='/images/expand_more.svg' style={{ width: 24 }} />
                                </div>
                                {itemClicked && itemClicked.ownerID === owner.OwnerStarId && itemClicked.metalCode === 'Pt' && (
                                  <div className={c('owners-item-allocation')}>
                                    {!allocationLoading && allocationDetails
                                      ? (
                                        <>
                                          <table>
                                            <tbody>
                                              <tr>
                                                <th>{t('explorer-parcel-link')}</th>
                                                <th>{t('explorer-allocation')}</th>
                                                <th>{t('explorer-grams')}</th>
                                              </tr>
                                              {allocationDetails.gramBarAllocationList.map(item =>
                                                <tr key={item.ParcelID}>
                                                  <td>
                                                    <a href={`https://silverbullion.com.sg/STAR-Grams?r=${item.ParcelID}`} target='_blank' rel='noopener noreferrer'>{item.ParcelName}</a>
                                                  </td>
                                                  <td>{numberFormatter.format(item.WeightToBeAssigned / item.Weight * 100)}%</td>
                                                  <td>{item.WeightToBeAssigned?.toFixed(6)}g</td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>
                                          <span>{t('explorer-allocation-as-of')} {allocationDetails?.updatedAt}</span>
                                        </>
                                      )
                                      : <p style={{ fontSize: 13, margin: '0 6px' }}>Loading...</p>}
                                  </div>
                                )}
                              </>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}
